/* eslint-disable react/prop-types */
import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";

import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

import { styled } from "@mui/material/styles";

import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import MDButton from "components/MDButton";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";

import MDBadge from "components/MDBadge";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import CircularProgress from "@mui/material/CircularProgress";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";

import Alert from "components/CustomAlert";

import { dateFormat } from "utils/constants";

import { useUploadPaymentReceiptMutation, useDeletePaymentReceiptMutation } from "services/orders";

import { useUploadFileMutation, useDeleteFileMutation } from "services/files";

import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

registerPlugin(FilePondPluginImagePreview);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

import MDBox from "components/MDBox";

import SingleCA from "../components/SingleCA";

const UploadCA = ({ companyId, companyName, filesList }) => {
  const [openReceiptDialog, setOpenReceiptDialog] = useState(false);

  const [filteredBalanceSheet, setFilteredBalanceSheet] = useState([]);
  const [filteredProfitLoss, setFilteredProfitLoss] = useState([]);

  const handleDialogOpen = () => {
    setOpenReceiptDialog(true);
  };

  const handleDialogClose = () => {
    setOpenReceiptDialog(false);
  };

  useEffect(() => {
    if (filesList.length > 0) {
      let balanceSheets = filesList.filter((item) => item.doc_type == "BALANCESHEET");
      let profitlossSheets = filesList.filter((item) => item.doc_type == "PROFITLOSS");

      let modifiedBalanceSheets = balanceSheets.map((item) => {
        if (item.filepath) {
          let fileExtension = item.filepath.split(".").pop().toLowerCase();
          if (fileExtension == "pdf") {
            return {
              id: item.id,
              company_id: item.company_id,
              description: item.description,
              doc_type: item.doc_type,
              filepath: item.filepath,
              file_type: "pdf",
            };
          } else if (["jpg", "jpeg", "png"].includes(fileExtension)) {
            return {
              id: item.id,
              company_id: item.company_id,
              description: item.description,
              doc_type: item.doc_type,
              filepath: item.filepath,
              file_type: "img",
            };
          } else if (["doc", "docx"].includes(fileExtension)) {
            const googleDocsViewerURL = `https://docs.google.com/gview?url=${encodeURIComponent(
              process.env.REACT_APP_URL + "/" + filepath
            )}&embedded=true`;
            return {
              id: item.id,
              company_id: item.company_id,
              description: item.description,
              doc_type: item.doc_type,
              filepath: googleDocsViewerURL,
              file_type: "doc",
            };
          } else {
            return {
              id: item.id,
              company_id: item.company_id,
              description: item.description,
              doc_type: item.doc_type,
              filepath: item.filepath,
              file_type: "img",
            };
          }
        }
      });

      let modifiedProfitlossSheets = profitlossSheets.map((item) => {
        if (item.filepath) {
          let fileExtension = item.filepath.split(".").pop().toLowerCase();
          if (fileExtension == "pdf") {
            return {
              id: item.id,
              company_id: item.company_id,
              description: item.description,
              doc_type: item.doc_type,
              filepath: item.filepath,
              file_type: "pdf",
            };
          } else if (["jpg", "jpeg", "png"].includes(fileExtension)) {
            return {
              id: item.id,
              company_id: item.company_id,
              description: item.description,
              doc_type: item.doc_type,
              filepath: item.filepath,
              file_type: "img",
            };
          } else if (["doc", "docx"].includes(fileExtension)) {
            const googleDocsViewerURL = `https://docs.google.com/gview?url=${encodeURIComponent(
              process.env.REACT_APP_URL + "/" + filepath
            )}&embedded=true`;
            return {
              id: item.id,
              company_id: item.company_id,
              description: item.description,
              doc_type: item.doc_type,
              filepath: googleDocsViewerURL,
              file_type: "doc",
            };
          } else {
            return {
              id: item.id,
              company_id: item.company_id,
              description: item.description,
              doc_type: item.doc_type,
              filepath: item.filepath,
              file_type: "img",
            };
          }
        }
      });

      if (balanceSheets.length > 0) setFilteredBalanceSheet(modifiedBalanceSheets);
      if (profitlossSheets.length > 0) setFilteredProfitLoss(modifiedProfitlossSheets);
    }
  }, [filesList]);

  return (
    <>
      <Tooltip title="Modify Credit Applications" placement="top">
        <MDButton
          color="warning"
          variant="contained"
          size="small"
          sx={{
            ml: 1,
            color: "#fff",
            fontSize: "10px!important",
            fontWeight: "500",
            letterSpacing: "0.5px",
          }}
          onClick={handleDialogOpen}
        >
          Modify Credit Applications
        </MDButton>
      </Tooltip>
      <BootstrapDialog
        // onClose={handleDialogClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="lg"
        sx={{
          "& .MuiPaper-root": {
            width: "900px",
          },
          "& .MuiPaper-root.MuiAlert-root": {
            width: "auto",
          },
        }}
        open={openReceiptDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2, color: "#4b4b4f" }} id="customized-dialog-title">
          Credit Applications of {companyName}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleDialogClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <GridContainer>
            {filteredBalanceSheet && filteredBalanceSheet.length > 0 ? (
              <>
                {filteredBalanceSheet[0] ? (
                  <GridItem xs={12} sm={12} md={6} lg={6} xl={6} sx={{ mb: 2 }}>
                    <SingleCA
                      id={filteredBalanceSheet[0].id}
                      title="Balance Sheet"
                      companyId={companyId}
                      filePath={filteredBalanceSheet[0].filepath}
                      fileType="BALANCESHEET"
                      handleDialogClose={handleDialogClose}
                    />
                  </GridItem>
                ) : (
                  <GridItem xs={12} sm={12} md={6} lg={6} xl={6} sx={{ mb: 2 }}>
                    <SingleCA
                      id={0}
                      title="Balance Sheet"
                      companyId={companyId}
                      filePath=""
                      fileType="BALANCESHEET"
                      handleDialogClose={handleDialogClose}
                    />
                  </GridItem>
                )}
                {filteredBalanceSheet[1] ? (
                  <GridItem xs={12} sm={12} md={6} lg={6} xl={6} sx={{ mb: 2 }}>
                    <SingleCA
                      id={filteredBalanceSheet[1].id}
                      title="Balance Sheet"
                      companyId={companyId}
                      filePath={filteredBalanceSheet[1].filepath}
                      fileType="BALANCESHEET"
                      handleDialogClose={handleDialogClose}
                    />
                  </GridItem>
                ) : (
                  <GridItem xs={12} sm={12} md={6} lg={6} xl={6} sx={{ mb: 2 }}>
                    <SingleCA
                      id={0}
                      title="Balance Sheet"
                      companyId={companyId}
                      filePath=""
                      fileType="BALANCESHEET"
                      handleDialogClose={handleDialogClose}
                    />
                  </GridItem>
                )}
              </>
            ) : (
              <>
                <GridItem xs={12} sm={12} md={6} lg={6} xl={6} sx={{ mb: 2 }}>
                  <SingleCA
                    id={0}
                    title="Balance Sheet"
                    companyId={companyId}
                    filePath=""
                    fileType="BALANCESHEET"
                    handleDialogClose={handleDialogClose}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6} xl={6} sx={{ mb: 2 }}>
                  <SingleCA
                    id={0}
                    title="Balance Sheet"
                    companyId={companyId}
                    filePath=""
                    fileType="BALANCESHEET"
                    handleDialogClose={handleDialogClose}
                  />
                </GridItem>
              </>
            )}

            {filteredProfitLoss && filteredProfitLoss.length > 0 ? (
              <>
                {filteredProfitLoss[0] ? (
                  <GridItem xs={12} sm={12} md={6} lg={6} xl={6} sx={{ mb: 2 }}>
                    <SingleCA
                      id={filteredProfitLoss[0].id}
                      title="ProfitLoss Sheet"
                      companyId={companyId}
                      filePath={filteredProfitLoss[0].filepath}
                      fileType="PROFITLOSS"
                      handleDialogClose={handleDialogClose}
                    />
                  </GridItem>
                ) : (
                  <GridItem xs={12} sm={12} md={6} lg={6} xl={6} sx={{ mb: 2 }}>
                    <SingleCA
                      id={0}
                      title="ProfitLoss Sheet"
                      companyId={companyId}
                      filePath=""
                      fileType="PROFITLOSS"
                      handleDialogClose={handleDialogClose}
                    />
                  </GridItem>
                )}
                {filteredProfitLoss[1] ? (
                  <GridItem xs={12} sm={12} md={6} lg={6} xl={6} sx={{ mb: 2 }}>
                    <SingleCA
                      id={filteredProfitLoss[1].id}
                      title="ProfitLoss Sheet"
                      companyId={companyId}
                      filePath={filteredProfitLoss[1].filepath}
                      fileType="PROFITLOSS"
                      handleDialogClose={handleDialogClose}
                    />
                  </GridItem>
                ) : (
                  <GridItem xs={12} sm={12} md={6} lg={6} xl={6} sx={{ mb: 2 }}>
                    <SingleCA
                      id={0}
                      title="ProfitLoss Sheet"
                      companyId={companyId}
                      filePath=""
                      fileType="PROFITLOSS"
                      handleDialogClose={handleDialogClose}
                    />
                  </GridItem>
                )}
              </>
            ) : (
              <>
                <GridItem xs={12} sm={12} md={6} lg={6} xl={6} sx={{ mb: 2 }}>
                  <SingleCA
                    id={0}
                    title="ProfitLoss Sheet"
                    companyId={companyId}
                    filePath=""
                    fileType="PROFITLOSS"
                    handleDialogClose={handleDialogClose}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6} xl={6} sx={{ mb: 2 }}>
                  <SingleCA
                    id={0}
                    title="ProfitLoss Sheet"
                    companyId={companyId}
                    filePath=""
                    fileType="PROFITLOSS"
                    handleDialogClose={handleDialogClose}
                  />
                </GridItem>
              </>
            )}
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <MDButton color="secondary" variant="gradient" autoFocus onClick={handleDialogClose}>
            Cancel
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

// UploadCA.propTypes = {
//   company_id: PropTypes.number.isRequired
// };

export default UploadCA;
