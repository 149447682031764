/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { styled } from "@mui/material/styles";

import PropTypes from "prop-types";

import axios from "axios";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Loading } from "notiflix/build/notiflix-loading-aio";

import Box from "@mui/material/Box";

import CircularProgress from "@mui/material/CircularProgress";

import MDBadge from "components/MDBadge";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import Edit from "@mui/icons-material/Edit";

import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ControlledTextInput from "components/FormInputs/ControlledTextInput";
import ControlledCheckbox from "components/FormInputs/ControlledCheckbox";
import ControlledSelect from "components/FormInputs/ControlledSelect";
import MDButton from "components/MDButton";

import CustomTabs from "components/CustomTabs";

import { globalCountry } from "store/entities/countries";

import noPreview from "assets/images/no-preview.png";

import {
  usePutCategoryMutation,
  useLazyGetAllResellerProductCategoriesQuery,
} from "services/categories";

import { postCategorySchema } from "../schemas/_model";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

import MDBox from "components/MDBox";

const EditCategoryDialog = ({ categoryData, categories, urlID }) => {
  const initialFormState = {
    name: "",
    parentId: 0,
    active: true,
    isParent: true,
  };

  const [openEditDialog, setOpenEditDialog] = useState(false);

  const selectedCountry = useSelector(globalCountry);

  const { control, watch, handleSubmit, setValue, reset } = useForm({
    resolver: yupResolver(postCategorySchema),
    defaultValues: initialFormState,
  });

  const isParent = watch("isParent");

  const [triggerCategories] = useLazyGetAllResellerProductCategoriesQuery();

  const [updateCategory] = usePutCategoryMutation();

  const handleClickOpen = () => {
    setOpenEditDialog(true);
    console.log(categoryData);
  };

  const handleClose = () => {
    setOpenEditDialog(false);
  };

  useEffect(() => {
    if (categoryData) {
      setValue("name", categoryData.name);
      setValue("active", categoryData.active);
      if (categoryData.parent_id == 0) {
        setValue("isParent", true);
      } else {
        setValue("isParent", false);
        setValue("parentId", categoryData.parent_id);
      }
    }
  }, [categoryData]);

  const onSubmit = (data) => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });

    data.id = categoryData.id;
    if (data.isParent) {
      data.parentId = 0;
    }

    try {
      updateCategory(data).then((response) => {
        console.log(response);
        if (response.error) {
          Notify.failure(response.error.data.message);
        } else {
          Notify.success("Succefully Added!");
        }
      });
    } catch (e) {
      console.log(e);
    } finally {
      Loading.remove(500);
      triggerCategories(selectedCountry.id);
      handleClose();
    }
  };

  return (
    <>
      <Tooltip title="Edit custom category" placement="top">
        <IconButton aria-label="view" size="small" sx={{ ml: 1 }} onClick={handleClickOpen}>
          <Edit color="warning" fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="xl"
        sx={{
          "& .MuiDialog-paper": {
            width: "500px",
          },
        }}
        open={openEditDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2, color: "#3f3f3f" }} id="customized-dialog-title">
          Edit Custom category
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box
            sx={{
              fontWeight: 500,
              color: "#3f3f3f",
              mt: 3,
            }}
          >
            <form>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                  <ControlledTextInput
                    name="name"
                    control={control}
                    labelText="Category name *"
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                  <ControlledCheckbox name="isParent" control={control} label="Is parent?" />
                </GridItem>
                {!isParent && (
                  <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ControlledSelect
                      name={"parentId"}
                      control={control}
                      label="Select Parent category *"
                      menu={{
                        id: "id",
                        value: "name",
                        title: "Categories",
                        list: categories,
                      }}
                      sx={{
                        "& .MuiFormLabel-root": {
                          backgroundColor: "#fff",
                        },
                        "& .MuiInputBase-root": {
                          height: "44px",
                        },
                      }}
                    />
                  </GridItem>
                )}
                <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                  <ControlledCheckbox name="active" control={control} label="Display status" />
                </GridItem>
              </GridContainer>
            </form>
          </Box>
        </DialogContent>
        <DialogActions>
          <MDButton
            type="submit"
            color="primary"
            variant="gradient"
            id="submit"
            onClick={handleSubmit(onSubmit)}
          >
            Sumbit
          </MDButton>
          <MDButton color="secondary" variant="gradient" autoFocus onClick={handleClose}>
            Cancel
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

EditCategoryDialog.propTypes = {
  // children: PropTypes.node.isRequired, // You can also use PropTypes.element if you want to enforce a single element.
  data: PropTypes.object.isRequired,
};

export default EditCategoryDialog;
