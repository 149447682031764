import React, { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";

import { styled } from "@mui/material/styles";

import { Loading } from "notiflix/build/notiflix-loading-aio";

import PropTypes from "prop-types";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import MDButton from "components/MDButton";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import MDBadge from "components/MDBadge";

import MDTypography from "components/MDTypography";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import CustomTabs from "components/CustomTabs";

import CompanySignupApplication from "./Tabs/CompanySignupApplication";
import CreditApplication from "./Tabs/CreditApplication";

import { useLazyGetCompanyDetailsByIdQuery } from "services/companies";

import { resetForwardSection } from "store/entities/notifications";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

import MDBox from "components/MDBox";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const CompanyDetails = ({ details, id, BDMList }) => {
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);

  const [triggerCompanyDetails, { data: companyDetails = {}, isLoading, isSuccess }] =
    useLazyGetCompanyDetailsByIdQuery({
      // pollingInterval: 5000,
    });

  const handleClickOpen = () => {
    setOpen(true);
    triggerCompanyDetails(id);
  };
  const handleClose = () => {
    setOpen(false);
    // dispatch(resetForwardSection());
  };

  useEffect(() => {
    isLoading
      ? Loading.dots({
          svgColor: "rgba(159, 3, 3, 0.8)",
        })
      : Loading.remove(500);
  }, [isLoading]);

  return (
    <>
      <Tooltip title="View Details" placement="top">
        <IconButton
          aria-label="view"
          size="small"
          sx={{ ml: 1 }}
          id={`companyId${id}`}
          onClick={handleClickOpen}
        >
          <VisibilityIcon color="info" fontSize="inherit" />
        </IconButton>
        {/* <MDButton
          variant="contained"
          size="small"
          color="info"
          sx={{
            ml: 1,
            color: "#fff",
            fontSize: "10px!important",
            fontWeight: "400",
          }}
          onClick={handleClickOpen}
        >
          View
        </MDButton> */}
      </Tooltip>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="xl"
        sx={{
          "& .MuiDialog-paper": {
            width: "90%",
          },
        }}
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2, color: "#3f3f3f" }} id="customized-dialog-title">
          Company Details: {details.name}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box>
            {companyDetails ? (
              <CustomTabs
                headerColor="primary"
                tabs={
                  details.is_credit_customer
                    ? [
                        {
                          tabName: "Company Signup Application",
                          tabContent: (
                            <CompanySignupApplication details={companyDetails} BDMList={BDMList} />
                          ),
                        },
                        {
                          tabName: "Credit Application",
                          tabContent: <CreditApplication details={companyDetails} id={id} />,
                        },
                      ]
                    : [
                        {
                          tabName: "Company Signup Application",
                          tabContent: (
                            <CompanySignupApplication details={companyDetails} BDMList={BDMList} />
                          ),
                        },
                      ]
                }
              />
            ) : (
              ""
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <MDButton color="secondary" variant="gradient" autoFocus onClick={handleClose}>
            Cancel
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

CompanyDetails.propTypes = {
  // children: PropTypes.node.isRequired, // You can also use PropTypes.element if you want to enforce a single element.
  details: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  BDMList: PropTypes.array,
};

export default CompanyDetails;
