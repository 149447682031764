/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import axios from "axios";

import { styled } from "@mui/material/styles";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import MenuItem from "@mui/material/MenuItem";

import TextField from "@mui/material/TextField";

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";
import { Block } from "notiflix/build/notiflix-block-aio";

// components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import MDAvatar from "components/MDAvatar";
import { CustomSelect } from "components/CustomSelect";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

// components
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DownloadingIcon from '@mui/icons-material/Downloading';

import Tooltip from "@mui/material/Tooltip";

import Edit from "@mui/icons-material/Edit";
import Close from "@mui/icons-material/Close";

// queries
import {
  useGetAllResellerProductsByCountryIdQuery,
  useGetAllResellerProductsQuery,
  useLazyGetAllResellerProductsByCountryIdQuery,
  useDeleteProductMutation,
  useGetWoocommerceSiteProductsMutation,
  usePostProductMutation,
  useUploadProductImageMutation,
  usePutProductMutation,
  useGetDistinctAttributesQuery,
  useResetFeaturedProductsMutation
} from "services/products";

import {
  useGetWoocommerceSiteCategoriesMutation,
  usePostCategoryNameMutation,
  useGetAllResellerProductCategoriesQuery,
  usePutCategoryNameMutation,
  useLazyGetAllResellerProductCategoriesQuery,
} from "services/categories";

import {
  useGetAllUrlsByCountryIdQuery,
  useLazyGetAllUrlsByCountryIdQuery,
} from "services/countries";

// import { useGetAllProductsFromStoreUrlQuery } from "services/store";

import {
  globalCountry,
  selectedCountryURL,
  setSelectedCountryURL,
  resetSelectedCountryURL,
} from "store/entities/countries";

import {
  setGlobalLoader,
  setGlobalLoaderMessage,
  resetGlobalLoader,
  loaderStatus,
  globalLoaderMessage,
} from "store/ui/loader";

import { resetStockId, getCurrentStockId } from "store/entities/stockNotifications";

import { actions as tableActions } from "store/ui/table";

import Alert from "components/CustomAlert";

import CircularProgress from "@mui/material/CircularProgress";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import noPreview from "assets/images/no-preview.png";

import EditInventoryDialog from "./EditInventoryDialog";
import AddInventoryDialog from "./AddInventoryDialog";
import AddInventory from "./AddInventory";

import ProductReorder from "./ProductReorder";

import AddToReOrderList from "./AddToReOrderList";

import { tables } from "utils/constants";
import {
  removeLocalStorageItemsWithPrefix,
  imageSrcDecoder,
  replaceHTMLEntityAnd,
  removeUnecessaryFields
} from "utils/helperFunctions";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function Inventory() {
  const selectedCountry = useSelector(globalCountry);

  const currentStockId = useSelector(getCurrentStockId);

  const selectedURL = useSelector(selectedCountryURL);

  const [imageLoading, setImageLoading] = useState(true);

  const [filterProducts, setFilterProducts] = useState([]);

  const [filterCategories, setFilterCategories] = useState([]);

  const [openProductReorderDialog, setOpenProductReorderDialog] = useState(false);

  const [reorderProducts, setReorderProducts] = useState([]);

  const navigate = useHistory();

  const dispatch = useDispatch();

  const {
    data: productList = [],
    isLoading,
    isSuccess,
  } = useGetAllResellerProductsByCountryIdQuery(selectedCountry.id);

  const {
    data: distinctAttributes = [],
    isLoading: isDistinctAttributesLoading,
    isSuccess: isDistinctAttributesSuccess,
  } = useGetDistinctAttributesQuery();

  const {
    data: categoriesList = [],
    isLoading: isCategoriesLoading,
    isSuccess: isCategoriesSuccess,
  } = useGetAllResellerProductCategoriesQuery(selectedCountry.id);

  const [triggerProducts] = useLazyGetAllResellerProductsByCountryIdQuery();

  const [triggerCategories] = useLazyGetAllResellerProductCategoriesQuery();

  // const {
  //   data: resellerProducts = [],
  //   isLoading: isResellerProductsLoading,
  //   isSuccess: isResellerProductsSuccess,
  // } = useGetAllResellerProductsQuery();

  const {
    data: urlsByCountryId = [],
    isLoading: isUrlsByCountryIdLoading,
    isSuccess: isUrlsByCountryIdSuccess,
  } = useGetAllUrlsByCountryIdQuery(selectedCountry.id);

  const [triggerUrls] = useLazyGetAllUrlsByCountryIdQuery();

  const [deleteProduct, result] = useDeleteProductMutation();

  const [uploadProductImage] = useUploadProductImageMutation();

  const [resetFeaturedProducts] = useResetFeaturedProductsMutation();

  const [postCategory] = usePostCategoryNameMutation();

  const [updateCategory] = usePutCategoryNameMutation();

  const [postProduct] = usePostProductMutation();

  const [updateProduct] = usePutProductMutation();

  const [fetchWoocommerceCategories] = useGetWoocommerceSiteCategoriesMutation();

  const [fetchWoocommerceProducts] = useGetWoocommerceSiteProductsMutation();

  const [fetchWoocommerceVaiations] = useGetWoocommerceSiteProductsMutation();

  let savedCategories = [];

  useEffect(() => {
    isLoading || isUrlsByCountryIdLoading || isDistinctAttributesLoading
      ? Loading.dots({ svgColor: "rgba(159, 3, 3, 0.8)" })
      : Loading.remove(500);
  }, [isLoading, isUrlsByCountryIdLoading, isDistinctAttributesLoading]);

  useEffect(() => {
    if (productList && productList.length > 0) {
      Block.dots("#tableWrapper", { svgColor: "rgba(159, 3, 3, 0.8)" });
      if (selectedURL && selectedURL.id) {
        let filteredData = productList.filter((item) => item.url_id == selectedURL.id);
        // let existingReorderProducts = productList.filter((item) => item.priority > 0);
        let existingReorderProducts = filteredData.filter((item) => item.priority > 0);
        createProductVariations(filteredData);
        setReorderProducts(existingReorderProducts);
      } else {
        setFilterProducts([]);
      }
      Block.remove("#tableWrapper", 2000);
    } else {
      setFilterProducts([]);
    }

    if (categoriesList && categoriesList.length > 0) {
      if (selectedURL && selectedURL.id) {
        let filteredData = categoriesList.filter((item) => item.url_id == selectedURL.id);
        setFilterCategories(filteredData);
      } else {
        setFilterCategories([]);
      }
    } else {
      setFilterCategories([]);
    }
  }, [productList, categoriesList, selectedURL, selectedCountry]);

  useEffect(() => {
    if (productList && productList.length > 0) {
      if (selectedCountry && currentStockId && currentStockId.id) {
        let productExist = productList.filter((product) => product.id == currentStockId.id);
        if (productExist && productExist.length > 0) {
          if (urlsByCountryId && urlsByCountryId.urls && urlsByCountryId.urls.length > 0) {
            let productUrlId = urlsByCountryId.urls.filter(
              (url) => url.id == productExist[0].url_id
            );
            if (productUrlId && productUrlId.length > 0)
              dispatch(
                setSelectedCountryURL({
                  id: productUrlId[0].id,
                  url: productUrlId[0].url,
                  c_key: productUrlId[0].cunsumer_key,
                  c_secret: productUrlId[0].cunsumer_secret,
                })
              );
          }
        }
        // dispatch(setSelectedCountryURL());
      }
    }
  }, [productList, selectedCountry, currentStockId]);

  const createProductVariations = (products) => {
    const sortedProducts = products.sort((a, b) => {
      // If parent_id is 0, sort it before others
      if (a.parent_id === 0 && b.parent_id !== 0) {
        return -1;
      } else if (a.parent_id !== 0 && b.parent_id === 0) {
        return 1;
      }
      // Otherwise, sort based on priority (higher priority comes first)
      return b.priority - a.priority;
    });

    const productMap = {};

    // Iterate through the response and create a mapping of parent IDs to products
    sortedProducts.forEach((product) => {
      const productId = product.id;
      const parentId = product.parent_id;

      // Create 'productVariations' property for each product
      const productWithVariations = Object.assign({}, product, { productVariations: [] });

      if (parentId === 0) {
        // If the product has no parent, add it to the main products array
        productMap[productId] = productWithVariations;
      } else {
        // If the product has a parent, add it as a variation to the parent
        if (productMap[parentId] && !productMap[parentId].productVariations) {
          productMap[parentId].productVariations = [];
        }
        if (productMap[parentId]) productMap[parentId].productVariations.push(product);
      }
    });

    Object.values(productMap).forEach((product) => {
      if (product.productVariations.length > 0) {
        const prices = product.productVariations.map((variation) => variation.price);
        const minPrice = Math.min(...prices);
        const maxPrice = Math.max(...prices);
        product.priceRange =
          minPrice == maxPrice ? `${product.price}` : `${minPrice} - ${maxPrice}`;
        product.minPrice = minPrice;
      } else {
        product.priceRange = `${product.price}`;
      }
    });

    let filterMappedProdcuts = Object.values(productMap)
      .filter((product) => !(product.type == "variable" && product.productVariations.length == 0))
      .sort((a, b) => b.priority - a.priority);

    // Set the products state with the main products array
    if (currentStockId && currentStockId.id) {
      let filteredMappedProducts = [...filterMappedProdcuts];
      let arrayIndex = filteredMappedProducts.findIndex((item) => item.id == currentStockId.id);
      if (arrayIndex > -1) {
        const [element] = filteredMappedProducts.splice(arrayIndex, 1);
        filteredMappedProducts.unshift(element);

        setFilterProducts(filteredMappedProducts);
      } else {
        setFilterProducts(filterMappedProdcuts);

        Notify.failure("Product not found!");
      }
    } else {
      setFilterProducts(filterMappedProdcuts);
    }
  };

  useEffect(() => {
    if (currentStockId.id)
      setTimeout(() => {
        const element = document.getElementById(`productId${currentStockId.id}`);
        if (element) {
          element.click();
        }
      }, 1000);
  }, [filterProducts, currentStockId]);

  const handleUrlChange = (id) => {
    if (id) {
      let selected = urlsByCountryId.urls.filter((item) => item.id == id);
      // console.log(selected);
      dispatch(
        setSelectedCountryURL({
          id: selected[0].id,
          url: selected[0].url,
          c_key: selected[0].cunsumer_key,
          c_secret: selected[0].cunsumer_secret,
        })
      );
    }
  };

  const clearAllSyncData = () => {
    removeLocalStorageItemsWithPrefix("fetchedWoocommerceCategories=");
    removeLocalStorageItemsWithPrefix("fetchedWoocommerceProducts=");
    removeLocalStorageItemsWithPrefix("fetchedWoocommerceProductVariations=");
    removeLocalStorageItemsWithPrefix("savedWoocommerceCategories=");

    removeLocalStorageItemsWithPrefix("fetchedWoocommerceSingleProductDetails=");
    removeLocalStorageItemsWithPrefix("fetchedWoocommerceSingleProductVariations=");
  };

  const resyncFailed = () => {
    dispatch(resetGlobalLoader());

    clearAllSyncData();

    Notify.failure("Something went wrong. Please try again!");
  };

  const reSyncWithWoocommerce = async (onlyNew = false, id, url, key, secret) => {
    dispatch(setGlobalLoaderMessage("Resyncing..."));

    dispatch(setGlobalLoader());

    clearAllSyncData();

    setTimeout(() => {
      dispatch(setGlobalLoaderMessage("Fetching categories from the woocommerce site..."));
    }, 2500);

    const uRLData = {
      id: id,
      url: url,
      cunsumer_key: key,
      cunsumer_secret: secret,
    };

    const areCategoriesFetchedFromWoocommerceSite = await fetchCategoriesFromWoocommerce(uRLData);

    if (!areCategoriesFetchedFromWoocommerceSite) {
      resyncFailed();
    } else {
      setTimeout(() => {
        dispatch(setGlobalLoaderMessage("Syncing categories..."));
      }, 1500);

      let getStoredCategories = JSON.parse(
        localStorage.getItem(`fetchedWoocommerceCategories=${uRLData.url}`)
      );

      // <-------------------------------------------------------------------- saving categories from the woocommerce site...

      savedCategories = [];
      // console.log(insertedCountryData);
      const ifCategoriesSaved = await sendPostCategoriesSequentially(
        getStoredCategories,
        uRLData.id,
        uRLData.url
      );

      if (!ifCategoriesSaved) {
        resyncFailed();

        triggerCategories(selectedCountry.id);
      }

      // <-------------------------------------------------------------------- Fetching products from the woocommerce site...
      setTimeout(() => {
        dispatch(setGlobalLoaderMessage("Fetching products from the woocommerce site..."));
      }, 1000);

      const areProductsFetchedFromWoocommerceSite = await fetchProductsFromWoocommerce(uRLData);

      if (!areProductsFetchedFromWoocommerceSite) {
        resyncFailed();
      } else {
        setTimeout(() => {
          dispatch(setGlobalLoaderMessage("Fetching product variations...."));
        }, 1000);

        let fetchedWoocommerceProducts = JSON.parse(
          localStorage.getItem(`fetchedWoocommerceProducts=${uRLData.url}`)
        );

        let variationCount = 0;

        for (const count of fetchedWoocommerceProducts) {
          if (count.type != "simple") variationCount++;
        }

        // console.log(insertedCountryData);
        const ifVariablesSaved = await fetchProductVariationsFromWoocommerce(
          fetchedWoocommerceProducts,
          uRLData,
          variationCount
        );

        if (!ifVariablesSaved) {
          resyncFailed();
        } else {
          dispatch(setGlobalLoaderMessage("Fetching product variations.... Done!"));

          setTimeout(() => {
            dispatch(setGlobalLoaderMessage("Syncing fetched products..."));
          }, 1000);

          let savedCategories = JSON.parse(
            localStorage.getItem(`savedWoocommerceCategories=${uRLData.url}`)
          );

          let modifiedFetchedWoocommerceProducts = JSON.parse(
            localStorage.getItem(`fetchedWoocommerceProducts=${uRLData.url}`)
          );

          let fetchedWoocommerceProductVariations = JSON.parse(
            localStorage.getItem(`fetchedWoocommerceProductVariations=${uRLData.url}`)
          );

          // <-------------------------------------------------------------------- saving products

          const ifProductsSaved = await sendPostProductsSequentially(
            onlyNew,
            savedCategories,
            modifiedFetchedWoocommerceProducts,
            fetchedWoocommerceProductVariations,
            uRLData.id,
            selectedCountry.id
          );

          if (!ifProductsSaved) {
            resyncFailed();

            triggerProducts(selectedCountry.id);
            triggerCategories(selectedCountry.id);
          } else {
            dispatch(setGlobalLoaderMessage("Saved successfully!"));

            setTimeout(() => {
              dispatch(setGlobalLoaderMessage("Good to go.."));

              removeLocalStorageItemsWithPrefix("fetchedWoocommerceCategories=");
              removeLocalStorageItemsWithPrefix("fetchedWoocommerceProducts=");
              removeLocalStorageItemsWithPrefix("fetchedWoocommerceProductVariations=");
              removeLocalStorageItemsWithPrefix("savedWoocommerceCategories=");

              removeLocalStorageItemsWithPrefix("fetchedWoocommerceSingleProductDetails=");
              removeLocalStorageItemsWithPrefix("fetchedWoocommerceSingleProductVariations=");

              setTimeout(() => {
                dispatch(resetGlobalLoader());
                Notify.success("All the categories and products synced successfully!");
              }, 1000);

              triggerProducts(selectedCountry.id);
              triggerCategories(selectedCountry.id);
            }, 1000);
          }
        }
      }

      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    }
  };

  const singleProductReSyncWithWoocommerce = async (id, url, key, secret, woocommerce_id) => {
    dispatch(setGlobalLoaderMessage("Resyncing..."));

    dispatch(setGlobalLoader());

    clearAllSyncData();

    setTimeout(() => {
      dispatch(setGlobalLoaderMessage("Checking if there are new categories..."));
    }, 2500);

    const uRLData = {
      id: id,
      url: url,
      cunsumer_key: key,
      cunsumer_secret: secret,
    };

    const areCategoriesFetchedFromWoocommerceSite = await fetchCategoriesFromWoocommerce(uRLData);

    if (!areCategoriesFetchedFromWoocommerceSite) {
      resyncFailed();
    } else {
      let getStoredCategories = JSON.parse(
        localStorage.getItem(`fetchedWoocommerceCategories=${uRLData.url}`)
      );

      // <-------------------------------------------------------------------- saving categories from the woocommerce site...

      savedCategories = [];
      // console.log(insertedCountryData);
      const ifCategoriesSaved = await sendPostCategoriesSequentially(
        getStoredCategories,
        uRLData.id,
        uRLData.url
      );

      if (!ifCategoriesSaved) {
        resyncFailed();

        triggerCategories(selectedCountry.id);
      }

      // <-------------------------------------------------------------------- Fetching products from the woocommerce site...
      setTimeout(() => {
        dispatch(setGlobalLoaderMessage("Fetching product details from the woocommerce site..."));
      }, 1000);

      const areSingleProductFromWoocommerceSite = await fetchSingleProductFromWoocommerce(
        uRLData,
        woocommerce_id
      );

      if (!areSingleProductFromWoocommerceSite) {
        resyncFailed();
      } else {
        let fetchedWoocommerceSingleProduct = [
          JSON.parse(
            localStorage.getItem(`fetchedWoocommerceSingleProductDetails=${woocommerce_id}`)
          ),
        ];

        let fetchedWoocommerceSingleProductVariations = localStorage.getItem(
          `fetchedWoocommerceSingleProductVariations=${woocommerce_id}`
        )
          ? JSON.parse(
              localStorage.getItem(`fetchedWoocommerceSingleProductVariations=${woocommerce_id}`)
            )
          : [];

        setTimeout(() => {
          dispatch(setGlobalLoaderMessage("Syncing product details"));
        }, 1000);

        dispatch(setGlobalLoaderMessage("Fetching product variations.... Done!"));

        setTimeout(() => {
          dispatch(setGlobalLoaderMessage("Syncing fetched products..."));
        }, 1000);

        const ifProductsSaved = await sendPostProductsSequentially(
          false,
          savedCategories,
          fetchedWoocommerceSingleProduct,
          fetchedWoocommerceSingleProductVariations,
          uRLData.id,
          selectedCountry.id,
          woocommerce_id
        );

        if (!ifProductsSaved) {
          resyncFailed();

          triggerProducts(selectedCountry.id);
          triggerCategories(selectedCountry.id);
        } else {
          dispatch(setGlobalLoaderMessage("Saved successfully!"));

          setTimeout(() => {
            dispatch(setGlobalLoaderMessage("Good to go.."));

            removeLocalStorageItemsWithPrefix("fetchedWoocommerceCategories=");
            removeLocalStorageItemsWithPrefix("fetchedWoocommerceProducts=");
            removeLocalStorageItemsWithPrefix("fetchedWoocommerceProductVariations=");
            removeLocalStorageItemsWithPrefix("savedWoocommerceCategories=");
            removeLocalStorageItemsWithPrefix("savedWoocommerceCategories=");

            removeLocalStorageItemsWithPrefix("fetchedWoocommerceSingleProductDetails=");
            removeLocalStorageItemsWithPrefix("fetchedWoocommerceSingleProductVariations=");

            setTimeout(() => {
              dispatch(resetGlobalLoader());
              Notify.success("product has been synced successfully!");
            }, 1000);

            triggerProducts(selectedCountry.id);
            triggerCategories(selectedCountry.id);
          }, 1000);
        }
      }

      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    }
  };

  const fetchCategoriesFromWoocommerce = async (uRLData) => {
    let { url, cunsumer_key, cunsumer_secret } = uRLData;

    try {
      let allCategoriesFetched = true;
      let categoriesArray = [];

      let currentPage = 1;
      let fullURL = `${url}/wp-json/wc/v3/products/categories`;

      // Loop until there are no more categories
      while (true) {
        const response = await fetchWoocommerceCategories({
          urlPart: fullURL,
          per_page: 100,
          page: currentPage,
          key: cunsumer_key,
          secret: cunsumer_secret,
        });

        const categories = await response.data;

        // Check if categories are empty, indicating the last page
        if (categories.length === 0) {
          // const hierarchicalCategories = createHierarchy(categoriesArray);
          localStorage.setItem(
            `fetchedWoocommerceCategories=${url}`,
            JSON.stringify(categoriesArray)
          );
          break; // Return true after all data has been fetched
        } else {
          categoriesArray.push(...categories);
        }
        currentPage += 1;
      }

      return allCategoriesFetched;
    } catch (error) {
      console.error("Error fetching products:", error);
      return false;
    } finally {
    }
  };

  const fetchProductsFromWoocommerce = async (uRLData) => {
    let { url, cunsumer_key, cunsumer_secret } = uRLData;
    try {
      let allProductsFetched = true;

      let productsArray = [];
      let currentPage = 1;
      let fullURL = `${url}/wp-json/wc/v3/products`;

      // Loop until there are no more categories
      while (true) {
        const response = await fetchWoocommerceProducts({
          urlPart: fullURL,
          per_page: 100,
          page: currentPage,
          requiredFields:
            "id,name,type,description,short_description,sku,images,attributes,related_ids,average_rating,rating_count,regular_price,price,variations",
          key: cunsumer_key,
          secret: cunsumer_secret,
        });

        const products = await response.data;

        // Check if categories are empty, indicating the last page
        if (products.length === 0) {
          localStorage.setItem(`fetchedWoocommerceProducts=${url}`, JSON.stringify(productsArray));
          break; // Return true after all data has been fetched
        } else {
          let modifiedProducts = [...products];
          for(let singleProduct of products) {
            if(singleProduct.status == "draft") {
              modifiedProducts = modifiedProducts.filter(item => item.id != singleProduct.id)
            }
          }
          if (modifiedProducts.length > 0) {
            for (let singleProduct of modifiedProducts) {
              let modifiedProduct = {};
              let metaData = [];
              if(singleProduct.meta_data && singleProduct.meta_data.length > 0){
                for (let meta_data of singleProduct.meta_data) {
                  if (
                    meta_data.key &&
                    meta_data.key == "wholesale_customer_wholesale_price"
                  ) {
                    metaData.push(meta_data);
                  }
                  // else {
                  //   let tempData = {
                  //     id: variation.id,
                  //     name: variation.name,
                  //     parent_id: variation.parent_id,
                  //     type: variation.type,
                  //   }
                  //   noneWholeSaleProducts.push(tempData);
                  // }
                }
              }

              modifiedProduct = {...singleProduct};
              delete modifiedProduct.meta_data;
              modifiedProduct.meta_data = metaData;

              if(Object.keys(modifiedProduct).length > 0) {
                // deleting the existing product
                modifiedProducts = modifiedProducts.filter(item => item.id != modifiedProduct.id);
              }

              // pushing the modified product
              modifiedProducts.push(removeUnecessaryFields(modifiedProduct));
            }
          }
          productsArray.push(...modifiedProducts);
        }
        currentPage += 1;
      }

      return allProductsFetched;
    } catch (error) {
      console.error("Error fetching products:", error);
      return false;
    } finally {
    }
  };

  const fetchSingleProductFromWoocommerce = async (uRLData, woocommerce_id) => {
    let { url, cunsumer_key, cunsumer_secret } = uRLData;
    try {
      let productDetailsFetched = true;

      let fullURL = `${url}/wp-json/wc/v3/products/${woocommerce_id}`;

      const response = await fetchWoocommerceProducts({
        urlPart: fullURL,
        per_page: 100,
        page: 1,
        requiredFields:
          "id,name,type,description,short_description,sku,images,attributes,related_ids,average_rating,rating_count,regular_price,price,variations",
        key: cunsumer_key,
        secret: cunsumer_secret,
      });

      const product = await response.data;

      if (product) {
        localStorage.setItem(
          `fetchedWoocommerceSingleProductDetails=${woocommerce_id}`,
          JSON.stringify(product)
        );
        if (product.type != "simple") {
          dispatch(setGlobalLoaderMessage(`Fetching product variations....`));

          // Loop until there are no more categories
          const response = await fetchWoocommerceVaiations({
            urlPart: `${url}/wp-json/wc/v3/products/${woocommerce_id}/variations`,
            per_page: 100,
            page: 1,
            requiredFields:
              "id,name,type,description,short_description,sku,images,attributes,related_ids,average_rating,rating_count,regular_price,price,variations",
            key: cunsumer_key,
            secret: cunsumer_secret,
          });

          const variations = await response.data;

          if (variations) {
            localStorage.setItem(
              `fetchedWoocommerceSingleProductVariations=${woocommerce_id}`,
              JSON.stringify(variations)
            );
          } else {
            productDetailsFetched = false;
          }
        }
      } else {
        productDetailsFetched = false;
      }

      return productDetailsFetched;
    } catch (error) {
      console.error("Error fetching products:", error);
      return false;
    } finally {
    }
  };

  const fetchProductVariationsFromWoocommerce = async (products, field, variationCount) => {
    let fetchedWoocommerceProducts = [...products];
    try {
      let allVariablesFetched = true;
      let variationsData = [];

      let count = 0;
      for (const product of fetchedWoocommerceProducts) {
        if (product.type != "simple") {
          count++;
          dispatch(
            setGlobalLoaderMessage(`Fetching product variations.... (${count}/${variationCount})`)
          );

          let fullURL = `${field.url}/wp-json/wc/v3/products/${product.id}/variations`;

          // Loop until there are no more categories
          const response = await fetchWoocommerceVaiations({
            urlPart: fullURL,
            per_page: 100,
            page: 1,
            requiredFields:
              "id,name,type,description,short_description,sku,images,attributes,related_ids,average_rating,rating_count,regular_price,price,variations",
            key: field.cunsumer_key,
            secret: field.cunsumer_secret,
          });

          const variations = await response.data;

          if (variations.length > 0) {
            for (let variation of variations) {
              let metaData = [];
              if(variation.meta_data && variation.meta_data.length > 0){
                for (let meta_data of variation.meta_data) {
                  if (
                    meta_data.key &&
                    meta_data.key == "wholesale_customer_wholesale_price"
                  ) {
                    metaData.push(meta_data);
                  }
                  // else {
                  //   let tempData = {
                  //     id: variation.id,
                  //     name: variation.name,
                  //     parent_id: variation.parent_id,
                  //     type: variation.type,
                  //   }
                  //   noneWholeSaleProducts.push(tempData);
                  // }
                }
              }
              let modifiedVariation = {...variation};
              delete modifiedVariation.meta_data;
              modifiedVariation.meta_data = metaData;
              variationsData.push(removeUnecessaryFields(modifiedVariation));
            }
          }
        }
      }

      localStorage.setItem(
        `fetchedWoocommerceProductVariations=${field.url}`,
        JSON.stringify(variationsData)
      );

      return allVariablesFetched;
    } catch (error) {
      console.error("Error fetching products:", error);
      return false;
    } finally {
    }
  };

  const sendPostCategoriesSequentially = async (getStoredCategories, id, url) => {
    try {
      let allRequestsSuccessful = true;

      const categoriesArray = Array.isArray(getStoredCategories)
        ? getStoredCategories
        : Object.values(getStoredCategories);

      savedCategories = [...Object.values(filterCategories)];

      // recursive function removed
      // let categoriesToImport = [];
      // let subCategoriesToImport = [];
      let remainingCategoriesToImport1 = [];
      let remainingCategoriesToImport2 = [];
      let remainingCategoriesToImport3 = [];
      let remainingCategoriesToImport4 = [];
      let remainingCategoriesToImport5 = [];

      for (const category of categoriesArray) {
        let filteredCategory = savedCategories.filter((item) => item.woocommerce_id == category.id);
        if (filteredCategory && filteredCategory.length > 0) {
          // let data = {};
          // data.name = replaceHTMLEntityAnd(category.name);
          // data.active = filteredCategory[0].active;
          // data.id = filteredCategory[0].id;
          // data.parentId = filteredCategory[0].parent_id;
          // try {
          //   const response = await updateCategory(data)
          //     // Check if the response is successful based on your API's structure
          //     if (!response.data.response) {
          //       // console.error(`Error posting category "${category.name}". Status: ${response.status}`);
          //       allRequestsSuccessful = false;
          //     }
          //   } catch (error) {
          //     // console.error(`Error posting category "${category.name}":`, error);
          //     allRequestsSuccessful = false;
          //   }
        } else {
          if (category.parent == 0) {
            let data = {};
            data.woocommerceId = category.id;
            data.urlId = id;
            data.parentId = 0;
            data.name = replaceHTMLEntityAnd(category.name);
            data.active = true;

            try {
              const response = await postCategory(data);

              // Check if the response is successful based on your API's structure
              if (!response.data.response) {
                // console.error(`Error posting category "${category.name}". Status: ${response.status}`);
                allRequestsSuccessful = false;
              } else {
                savedCategories.push(response.data.response);
                let updatedSavedCategories = [...savedCategories];
                localStorage.setItem(
                  `savedWoocommerceCategories=${url}`,
                  JSON.stringify(updatedSavedCategories)
                );
                // console.log(`Category "${category.name}" posted successfully`);
              }
            } catch (error) {
              console.error(`Error posting category "${category.name}":`, error);
              allRequestsSuccessful = false;
            }
          } else {
            remainingCategoriesToImport1.push(category);
          }
        }
      }

      if (remainingCategoriesToImport1 && remainingCategoriesToImport1.length > 0) {
        for (const category of remainingCategoriesToImport1) {
          let filteredCategory = savedCategories.filter(
            (item) => item.woocommerce_id == category.parent
          );
          if (filteredCategory && filteredCategory.length > 0) {
            let data = {
              woocommerceId: category.id,
              urlId: id, // Assuming id is defined somewhere in your code
              parentId: filteredCategory[0].id,
              name: replaceHTMLEntityAnd(category.name),
              active: true,
            };

            try {
              const response = await postCategory(data);

              if (!response.data.response) {
                allRequestsSuccessful = false;
              } else {
                savedCategories.push(response.data.response);
                let updatedSavedCategories = [...savedCategories];
                localStorage.setItem(
                  `savedWoocommerceCategories=${url}`,
                  JSON.stringify(updatedSavedCategories)
                );
              }
            } catch (error) {
              console.error(`Error posting category "${category.name}":`, error);
              allRequestsSuccessful = false;
            }
          } else {
            remainingCategoriesToImport2.push(category);
          }
        }
      }

      if (remainingCategoriesToImport2 && remainingCategoriesToImport2.length > 0) {
        for (const category of remainingCategoriesToImport2) {
          let filteredCategory = savedCategories.filter(
            (item) => item.woocommerce_id == category.parent
          );
          if (filteredCategory && filteredCategory.length > 0) {
            let data = {
              woocommerceId: category.id,
              urlId: id, // Assuming id is defined somewhere in your code
              parentId: filteredCategory[0].id,
              name: replaceHTMLEntityAnd(category.name),
              active: true,
            };

            try {
              const response = await postCategory(data);

              if (!response.data.response) {
                allRequestsSuccessful = false;
              } else {
                savedCategories.push(response.data.response);
                let updatedSavedCategories = [...savedCategories];
                localStorage.setItem(
                  `savedWoocommerceCategories=${url}`,
                  JSON.stringify(updatedSavedCategories)
                );
              }
            } catch (error) {
              console.error(`Error posting category "${category.name}":`, error);
              allRequestsSuccessful = false;
            }
          } else {
            remainingCategoriesToImport3.push(category);
          }
        }
      }

      if (remainingCategoriesToImport3 && remainingCategoriesToImport3.length > 0) {
        for (const category of remainingCategoriesToImport3) {
          let filteredCategory = savedCategories.filter(
            (item) => item.woocommerce_id == category.parent
          );
          if (filteredCategory && filteredCategory.length > 0) {
            let data = {
              woocommerceId: category.id,
              urlId: id, // Assuming id is defined somewhere in your code
              parentId: filteredCategory[0].id,
              name: replaceHTMLEntityAnd(category.name),
              active: true,
            };

            try {
              const response = await postCategory(data);

              if (!response.data.response) {
                allRequestsSuccessful = false;
              } else {
                savedCategories.push(response.data.response);
                let updatedSavedCategories = [...savedCategories];
                localStorage.setItem(
                  `savedWoocommerceCategories=${url}`,
                  JSON.stringify(updatedSavedCategories)
                );
              }
            } catch (error) {
              console.error(`Error posting category "${category.name}":`, error);
              allRequestsSuccessful = false;
            }
          } else {
            remainingCategoriesToImport4.push(category);
          }
        }
      }

      if (remainingCategoriesToImport4 && remainingCategoriesToImport4.length > 0) {
        for (const category of remainingCategoriesToImport4) {
          let filteredCategory = savedCategories.filter(
            (item) => item.woocommerce_id == category.parent
          );
          if (filteredCategory && filteredCategory.length > 0) {
            let data = {
              woocommerceId: category.id,
              urlId: id, // Assuming id is defined somewhere in your code
              parentId: filteredCategory[0].id,
              name: replaceHTMLEntityAnd(category.name),
              active: true,
            };

            try {
              const response = await postCategory(data);

              if (!response.data.response) {
                allRequestsSuccessful = false;
              } else {
                savedCategories.push(response.data.response);
                let updatedSavedCategories = [...savedCategories];
                localStorage.setItem(
                  `savedWoocommerceCategories=${url}`,
                  JSON.stringify(updatedSavedCategories)
                );
              }
            } catch (error) {
              console.error(`Error posting category "${category.name}":`, error);
              allRequestsSuccessful = false;
            }
          } else {
            remainingCategoriesToImport5.push(category);
          }
        }
      }

      if (remainingCategoriesToImport5 && remainingCategoriesToImport5.length > 0) {
        for (const category of remainingCategoriesToImport5) {
          let filteredCategory = savedCategories.filter(
            (item) => item.woocommerce_id == category.parent
          );
          if (filteredCategory && filteredCategory.length > 0) {
            let data = {
              woocommerceId: category.id,
              urlId: id, // Assuming id is defined somewhere in your code
              parentId: filteredCategory[0].id,
              name: replaceHTMLEntityAnd(category.name),
              active: true,
            };

            try {
              const response = await postCategory(data);

              if (!response.data.response) {
                allRequestsSuccessful = false;
              } else {
                savedCategories.push(response.data.response);
                let updatedSavedCategories = [...savedCategories];
                localStorage.setItem(
                  `savedWoocommerceCategories=${url}`,
                  JSON.stringify(updatedSavedCategories)
                );
              }
            } catch (error) {
              console.error(`Error posting category "${category.name}":`, error);
              allRequestsSuccessful = false;
            }
          }
        }
      }

      // importChildCategories(savedCategories, allRequestsSuccessful, remainingCategoriesToImport, id, url)

      if (savedCategories.length > 0)
        localStorage.setItem(`savedWoocommerceCategories=${url}`, JSON.stringify(savedCategories));
      // console.log(remainingCategoriesToImport1);
      // console.log(remainingCategoriesToImport2);
      // console.log(remainingCategoriesToImport3);
      // console.log(remainingCategoriesToImport4);
      return allRequestsSuccessful;
    } catch (error) {
      console.error("Error sending POST requests:", error);
      return false;
    }
  };

  // async function importChildCategories(savedCategories, allRequestsSuccessful, remainingCategoriesToImport, urlID, url) {
  //   while (remainingCategoriesToImport.length > 0) {
  //       let categories = remainingCategoriesToImport;
  //       remainingCategoriesToImport = [];

  //       for (const category of categories) {
  //           let filteredCategory = savedCategories.filter(item => item.woocommerce_id == category.parent);
  //           if (filteredCategory && filteredCategory.length > 0) {
  //               let data = {
  //                   woocommerceId: category.id,
  //                   urlId: urlID, // Assuming id is defined somewhere in your code
  //                   parentId: filteredCategory[0].id,
  //                   name: replaceHTMLEntityAnd(category.name),
  //                   active: true
  //               };

  //               try {
  //                   const response = await postCategory(data);

  //                   if (!response.data.response) {
  //                       allRequestsSuccessful = false;
  //                   } else {
  //                       savedCategories.push(response.data.response);
  //                       let updatedSavedCategories = [...savedCategories];
  //                       localStorage.setItem(`savedWoocommerceCategories=${url}`, JSON.stringify(updatedSavedCategories));
  //                   }
  //               } catch (error) {
  //                   console.error(`Error posting category "${category.name}":`, error);
  //                   allRequestsSuccessful = false;
  //               }
  //           } else {
  //               remainingCategoriesToImport.push(category);
  //           }
  //       }
  //   }
  // }

  const sendPostProductsSequentially = async (
    onlyNew,
    savedCategories,
    modifiedFetchedWoocommerceProducts,
    fetchedProductVariations,
    urlId,
    countryId,
    woocommerce_id
  ) => {
    // let noneWholeSaleProducts = [];
    // let draftProducts = [];

    // let deletedProducts = [];
    // let deletedProductVariations = [];
    try {
      let allRequestsSuccessful = true;

      const productsArray = Array.isArray(modifiedFetchedWoocommerceProducts)
        ? modifiedFetchedWoocommerceProducts
        : Object.values(modifiedFetchedWoocommerceProducts);

      let currentProducts = [...Object.values(productList.filter((item) => item.url_id == urlId))];

      for (const product of productsArray) {
        // if(product.status == "draft") {
        //   draftProducts.push(product);
        // }

        if (product.status == "publish") {
          if (product.type == "simple") {
            let filteredProduct = currentProducts.filter(
              (item) => item.parent_id == 0 && item.woocommerce_id == product.id
            );

            let isDigitalProduct = false;

            if (product.shipping_class == "physical") {
              isDigitalProduct = false;
            } else if (product.shipping_class == "digital") {
              isDigitalProduct = true;
            } else {
              if (product.virtual) {
                isDigitalProduct = product.virtual;
              }
            }

            // category filtering
            let missingCategories = [];
            if (filteredProduct &&
              filteredProduct.length > 0 &&
              filteredProduct[0].proucts_category &&
              filteredProduct[0].proucts_category.length > 0
            ) {
              if (product.categories && product.categories.length > 0) {
                for (let wooocommerceCategory of product.categories) {
                  let ifCategoryExist = savedCategories.filter(
                    (item) => item.woocommerce_id == wooocommerceCategory.id
                  );
                  if (ifCategoryExist && ifCategoryExist.length > 0) {
                    let ifCategoryExistInProduct = filteredProduct[0].proucts_category.filter(
                      (item) => item.id == ifCategoryExist[0].id
                    );
                    if (!(ifCategoryExistInProduct && ifCategoryExistInProduct.length > 0)) {
                      missingCategories.push(ifCategoryExist[0].id);
                    }
                  }
                }
              }
            } else {
              const filteredIds = savedCategories
                .filter((savedCategory) =>
                  product.categories.some(
                    (woocommerceCategory) => woocommerceCategory.id === savedCategory.woocommerce_id
                  )
                )
                .map((savedCategory) => savedCategory.id);
              missingCategories.push(...filteredIds);
            }

            // console.log(missingCategories);

            if (filteredProduct && filteredProduct.length > 0) {
              let id = filteredProduct[0].id;
              let data = {};

              // getting wholesale price as the reseller price
              let resellerPrice = 0;

              if (product.meta_data && product.meta_data.length > 0) {
                for (let meta_data of product.meta_data) {
                  if (meta_data.key && meta_data.key == "wholesale_customer_wholesale_price") {
                    resellerPrice = parseFloat(meta_data.value);
                  }
                  // else {
                  //   let tempData = {
                  //     id: product.id,
                  //     name: product.name,
                  //     parent_id: product.parent_id,
                  //     type: product.type,
                  //   }
                  //   noneWholeSaleProducts.push(tempData);
                  // }
                }
              }

              data.countryId = countryId;
              data.parentId = 0;
              data.productId = product.id;
              data.urlId = urlId;
              data.description = product.description
                ? product.description
                : filteredProduct[0].description
                ? filteredProduct[0].description
                : "...";
              data.shortDescription = product.short_description
                ? product.short_description
                : filteredProduct[0].short_description
                ? filteredProduct[0].short_description
                : "...";
              data.attributes = [];
              data.categories = [...new Set(missingCategories)];
              data.sku = product.sku ? product.sku : filteredProduct[0].sku;
              data.averageRating = product.average_rating
                ? parseFloat(product.average_rating)
                : filteredProduct[0].average_rating;
              data.ratingCount = product.rating_count
                ? product.rating_count
                : filteredProduct[0].rating_count;
              data.retailPrice = product.regular_price
                ? parseFloat(product.regular_price)
                : filteredProduct[0].retail_price;
              data.name = replaceHTMLEntityAnd(product.name);

              data.price = resellerPrice ? resellerPrice : filteredProduct[0].price;

              // data.price = filteredProduct[0].price;
              data.inStock = product.stock_status == "outofstock" ? false : true;
              data.isFeaturedProduct = filteredProduct[0].isFeaturedProduct;
              data.isDigitalGood = isDigitalProduct;

              if(!onlyNew) {
                const simplePutResponse = await updateProduct({ id, data });

                if (simplePutResponse.data.status != "success") {
                  allRequestsSuccessful = false;
                }
              }
            } else {
              const filteredIds = savedCategories
                .filter((savedCategory) =>
                  product.categories.some(
                    (woocommerceCategory) => woocommerceCategory.id === savedCategory.woocommerce_id
                  )
                )
                .map((savedCategory) => savedCategory.id);

              let resellerPrice = 0;

              if (product.meta_data && product.meta_data.length > 0) {
                for (let meta_data of product.meta_data) {
                  if (meta_data.key && meta_data.key == "wholesale_customer_wholesale_price") {
                    resellerPrice = parseFloat(meta_data.value);
                  }
                  // else {
                  //   let tempData = {
                  //     id: product.id,
                  //     name: product.name,
                  //     parent_id: product.parent_id,
                  //     type: product.type,
                  //   }
                  //   noneWholeSaleProducts.push(tempData);
                  // }
                }
              }

              let data = {
                woocommerceId: product.id,
                parentId: 0,
                countryId: countryId,
                isDigitalGood: isDigitalProduct,
                inStock: product.stock_status == "instock" ? true : false,
                price: resellerPrice ? resellerPrice : parseFloat(product.price),
                retailPrice: product.regular_price ? parseFloat(product.regular_price) : 0,
                isFeaturedProduct: false,
                urlId: urlId,
                digitalKeys:
                  isDigitalProduct && product.stock_status == "instock"
                    ? ["Pending", "Pending", "Pending", "Pending", "Pending"]
                    : [],
                images: [],
                attributes: [],
                categories: [...new Set(filteredIds)],
                averageRating: product.average_rating ? parseFloat(product.average_rating) : 5,
                description: product.description ? product.description : "...",
                name: replaceHTMLEntityAnd(product.name),
                ratingCount: product.rating_count,
                shortDescription: product.short_description ? product.short_description : "...",
                sku: product.sku,
                type: product.type,
              };

              const simplePostResponse = await postProduct(data);

              if (simplePostResponse.data?.response) {
                if (product.images && product.images.length > 0) {
                  let imageArray = [];
                  for (let image of product.images) {
                    imageArray.push(`${image.src}`);
                  }
                  let data = {
                    imageUrls: imageArray,
                  };
                  const imageResponse = await uploadProductImage({
                    id: simplePostResponse.data.response.id,
                    data,
                  });

                  // if (!imageResponse.data.response) {
                  //     return false;
                  // }
                }
              }

              if (!simplePostResponse.data.response) {
                allRequestsSuccessful = false;
              }
            }
          } else {
            let filteredProduct = currentProducts.filter(
              (item) => item.parent_id == 0 && item.woocommerce_id == product.id
            );

            let isDigitalProduct = false;

            if (product.shipping_class == "physical") {
              isDigitalProduct = false;
            } else if (product.shipping_class == "digital") {
              isDigitalProduct = true;
            } else {
              if (product.virtual) {
                isDigitalProduct = product.virtual;
              }
            }

            // category filtering
            let missingCategories = [];
            if (filteredProduct &&
              filteredProduct.length > 0 &&
              filteredProduct[0].proucts_category &&
              filteredProduct[0].proucts_category.length > 0
            ) {
              if (product.categories && product.categories.length > 0) {
                for (let wooocommerceCategory of product.categories) {
                  let ifCategoryExist = savedCategories.filter(
                    (item) => item.woocommerce_id == wooocommerceCategory.id
                  );
                  if (ifCategoryExist && ifCategoryExist.length > 0) {
                    let ifCategoryExistInProduct = filteredProduct[0].proucts_category.filter(
                      (item) => item.category_id == ifCategoryExist[0].id
                    );
                    if (!(ifCategoryExistInProduct && ifCategoryExistInProduct.length > 0)) {
                      missingCategories.push(ifCategoryExist[0].id);
                    }
                  }
                }
              }
            }

            // console.log(missingCategories);

            if (filteredProduct && filteredProduct.length > 0) {
              let id = filteredProduct[0].id;
              let data = {};

              let filteredIds = [];

              if (woocommerce_id) {
                if (filteredProduct[0] && filteredProduct[0].proucts_category.length > 0) {
                  for (let categoryId of filteredProduct[0].proucts_category) {
                    filteredIds.push(categoryId.category_id);
                  }
                } else {
                  const categories = savedCategories
                    .filter((savedCategory) =>
                      product.categories.some(
                        (woocommerceCategory) =>
                          woocommerceCategory.id === savedCategory.woocommerce_id
                      )
                    )
                    .map((savedCategory) => savedCategory.id);
                  filteredIds.push(...categories);
                }
              } else {
                if (filteredProduct[0] && filteredProduct[0].proucts_category.length > 0) {
                  for (let categoryId of filteredProduct[0].proucts_category) {
                    filteredIds.push(categoryId.category_id);
                  }
                } else {
                  let categories = savedCategories
                    .filter((savedCategory) =>
                      product.categories.some(
                        (woocommerceCategory) =>
                          woocommerceCategory.id === savedCategory.woocommerce_id
                      )
                    )
                    .map((savedCategory) => savedCategory.id);
                  filteredIds.push(...categories);
                }
              }

              if (
                filteredProduct[0].proucts_category &&
                filteredProduct[0].proucts_category.length > 0
              ) {
                if (product.categories && product.categories.length > 0) {
                  for (let wooocommerceCategory of product.categories) {
                    let ifCategoryExist = savedCategories.filter(
                      (item) => item.woocommerce_id == wooocommerceCategory.id
                    );
                    if (ifCategoryExist && ifCategoryExist.length > 0) {
                      let ifCategoryExistInProduct = filteredProduct[0].proucts_category.filter(
                        (item) => item.id == ifCategoryExist[0].id
                      );
                      if (!(ifCategoryExistInProduct && ifCategoryExistInProduct.length > 0)) {
                        filteredIds.push(ifCategoryExist[0].id);
                      }
                    }
                  }
                }
              } else {
                const filteredIds = savedCategories
                  .filter((savedCategory) =>
                    product.categories.some(
                      (woocommerceCategory) =>
                        woocommerceCategory.id === savedCategory.woocommerce_id
                    )
                  )
                  .map((savedCategory) => savedCategory.id);
                filteredIds.push(...filteredIds);
              }

              let resellerPrice = 0;

              if (product.meta_data && product.meta_data.length > 0) {
                for (let meta_data of product.meta_data) {
                  if (meta_data.key && meta_data.key == "wholesale_customer_wholesale_price") {
                    resellerPrice = parseFloat(meta_data.value);
                  }
                  // else {
                  //   let tempData = {
                  //     id: product.id,
                  //     name: product.name,
                  //     parent_id: product.parent_id,
                  //     type: product.type,
                  //   }
                  //   noneWholeSaleProducts.push(tempData);
                  // }
                }
              }

              let variableParentPrice = resellerPrice;
              let variationProducts = fetchedProductVariations.filter(
                (item) => item.parent_id == product.id
              );

              if (variationProducts && variationProducts.length > 0) {
                let prices = [];
                for (let variationProduct of variationProducts) {
                  if (variationProduct.status == "publish") {
                    if (variationProduct.meta_data && variationProduct.meta_data.length > 0) {
                      for (let meta_data of variationProduct.meta_data) {
                        if (
                          meta_data.key &&
                          meta_data.key == "wholesale_customer_wholesale_price"
                        ) {
                          prices.push(parseFloat(meta_data.value));
                        }
                        // else {
                        //   let tempData = {
                        //     id: fetchedVariation.id,
                        //     name: fetchedVariation.name,
                        //     parent_id: fetchedVariation.parent_id,
                        //     type: fetchedVariation.type,
                        //   }
                        //   noneWholeSaleProducts.push(tempData);
                        // }
                      }
                    }
                  }
                }
                variableParentPrice = Math.min(...prices);
              }

              data.countryId = countryId;
              data.parentId = 0;
              data.productId = product.id;
              data.urlId = urlId;
              data.description = product.description
                ? product.description
                : filteredProduct[0].description
                ? filteredProduct[0].description
                : "...";
              data.shortDescription = product.short_description
                ? product.short_description
                : filteredProduct[0].short_description
                ? filteredProduct[0].short_description
                : "...";
              data.attributes = [];
              data.categories = [...new Set(missingCategories)];
              data.sku = product.sku ? product.sku : filteredProduct[0].sku;
              data.averageRating = product.average_rating
                ? parseFloat(product.average_rating)
                : filteredProduct[0].average_rating;
              data.ratingCount = product.rating_count
                ? product.rating_count
                : filteredProduct[0].rating_count;
              data.retailPrice = product.regular_price
                ? parseFloat(product.regular_price)
                : filteredProduct[0].retail_price;
              data.name = replaceHTMLEntityAnd(product.name);

              data.price = variableParentPrice
                ? variableParentPrice
                : resellerPrice
                ? resellerPrice
                : filteredProduct[0].price;

              // data.price = filteredProduct[0].price;
              data.inStock = product.stock_status == "instock" ? true : false;
              data.isFeaturedProduct = filteredProduct[0].isFeaturedProduct;
              data.isDigitalGood = isDigitalProduct;

              let variableParentId = filteredProduct[0].id;

              if(!onlyNew) {
                const variableParentUpdateResponse = await updateProduct({ id, data });

                if (variableParentUpdateResponse.data.status != "success") {
                  allRequestsSuccessful = false;
                } else {
                  let currentVariations = currentProducts.filter((item) => item.parent_id == id);
                  let filteredFetchedVariations = fetchedProductVariations.filter(
                    (item) => item.parent_id == product.id
                  );

                  for (let fetchedVariation of filteredFetchedVariations) {
                    let matchedVariation = currentVariations.filter(
                      (variation) => variation.woocommerce_id == fetchedVariation.id
                    );

                    let isVariationDigitalProduct = false;

                    if (fetchedVariation.shipping_class) {
                      if (fetchedVariation.shipping_class == "physical") {
                        isVariationDigitalProduct = false;
                      } else if (fetchedVariation.shipping_class == "digital") {
                        isVariationDigitalProduct = true;
                      } else {
                        if (fetchedVariation.virtual) {
                          isVariationDigitalProduct = fetchedVariation.virtual;
                        }
                      }
                    } else {
                      if (fetchedVariation.virtual) {
                        isVariationDigitalProduct = fetchedVariation.virtual;
                      } else {
                        isVariationDigitalProduct = isDigitalProduct;
                      }
                    }

                    if (matchedVariation && matchedVariation.length > 0) {
                      let id = matchedVariation[0].id;
                      let data = {};

                      // getting wholesale price as the reseller price
                      let resellerVariationPrice = 0;

                      if (fetchedVariation.meta_data && fetchedVariation.meta_data.length > 0) {
                        for (let meta_data of fetchedVariation.meta_data) {
                          if (
                            meta_data.key &&
                            meta_data.key == "wholesale_customer_wholesale_price"
                          ) {
                            resellerVariationPrice = parseFloat(meta_data.value);
                          }
                          // else {
                          //   let tempData = {
                          //     id: fetchedVariation.id,
                          //     name: fetchedVariation.name,
                          //     parent_id: fetchedVariation.parent_id,
                          //     type: fetchedVariation.type,
                          //   }
                          //   noneWholeSaleProducts.push(tempData);
                          // }
                        }
                      }

                      data.countryId = countryId;
                      data.parentId = variableParentId;
                      data.productId = fetchedVariation.id;
                      data.urlId = urlId;
                      data.description = fetchedVariation.description
                        ? fetchedVariation.description
                        : matchedVariation[0].description
                        ? matchedVariation[0].description
                        : "...";
                      data.shortDescription = fetchedVariation.short_description
                        ? fetchedVariation.short_description
                        : matchedVariation[0].short_description
                        ? matchedVariation[0].short_description
                        : "...";
                      data.attributes = [];
                      data.categories = [];
                      data.sku = fetchedVariation.sku
                        ? fetchedVariation.sku
                        : matchedVariation[0].sku;
                      data.averageRating = fetchedVariation.average_rating
                        ? parseFloat(fetchedVariation.average_rating)
                        : matchedVariation[0].average_rating;
                      data.ratingCount = fetchedVariation.rating_count
                        ? fetchedVariation.rating_count
                        : matchedVariation[0].rating_count;
                      data.retailPrice = fetchedVariation.regular_price
                        ? parseFloat(fetchedVariation.regular_price)
                        : matchedVariation[0].retail_price;
                      data.name = fetchedVariation.name
                        ? fetchedVariation.name
                        : replaceHTMLEntityAnd(product.name);

                      data.price = resellerVariationPrice
                        ? resellerVariationPrice
                        : matchedVariation[0].price;

                      // data.price = matchedVariation[0].price;
                      data.inStock = fetchedVariation.stock_status == "instock" ? true : false;
                      data.isFeaturedProduct = matchedVariation[0].isFeaturedProduct;
                      data.isDigitalGood = isVariationDigitalProduct;

                      const variationUpdateResponse = await updateProduct({ id, data });

                      if (variationUpdateResponse.data.status != "success") {
                        allRequestsSuccessful = false;
                      }
                    } else {
                      if (fetchedVariation.status == "publish") {
                        let variationAttributes = [];

                        if (fetchedVariation.attributes && fetchedVariation.attributes.length > 0) {
                          for (let attribute of fetchedVariation.attributes) {
                            let attributeData = {};
                            attributeData.attribute = attribute.name;
                            attributeData.option = attribute.option;
                            variationAttributes.push(attributeData);
                          }
                        }

                        // getting wholesale price as the reseller price
                        let resellerVariationPrice = 0;

                        if (fetchedVariation.meta_data && fetchedVariation.meta_data.length > 0) {
                          for (let meta_data of fetchedVariation.meta_data) {
                            if (
                              meta_data.key &&
                              meta_data.key == "wholesale_customer_wholesale_price"
                            ) {
                              resellerPrice = parseFloat(meta_data.value);
                            }
                            // else {
                            //   let tempData = {
                            //     id: fetchedVariation.id,
                            //     name: fetchedVariation.name,
                            //     parent_id: fetchedVariation.parent_id,
                            //     type: fetchedVariation.type,
                            //   }
                            //   noneWholeSaleProducts.push(tempData);
                            // }
                          }
                        }

                        let variationData = {
                          woocommerceId: fetchedVariation.id,
                          parentId: variableParentId,
                          countryId: countryId,
                          isDigitalGood: isVariationDigitalProduct,
                          inStock: fetchedVariation.stock_status == "instock" ? true : false,
                          price: resellerVariationPrice
                            ? resellerVariationPrice
                            : fetchedVariation.price
                            ? parseFloat(fetchedVariation.price)
                            : 0,
                          retailPrice: fetchedVariation.regular_price
                            ? parseFloat(fetchedVariation.regular_price)
                            : 0,
                          isFeaturedProduct: false,
                          urlId: urlId,
                          digitalKeys:
                            isVariationDigitalProduct && fetchedVariation.stock_status == "instock"
                              ? ["Pending", "Pending", "Pending", "Pending", "Pending"]
                              : [],
                          images: [],
                          attributes: variationAttributes,
                          categories: [...new Set(filteredIds)],
                          averageRating: fetchedVariation.average_rating
                            ? parseFloat(fetchedVariation.average_rating)
                            : 5,
                          description: fetchedVariation.description
                            ? fetchedVariation.description
                            : product.description
                            ? product.description
                            : "...",
                          name: fetchedVariation.name
                            ? fetchedVariation.name
                            : replaceHTMLEntityAnd(product.name),
                          ratingCount: fetchedVariation.rating_count
                            ? fetchedVariation.rating_count
                            : product.rating_count,
                          shortDescription: fetchedVariation.short_description
                            ? fetchedVariation.short_description
                            : product.short_description
                            ? product.short_description
                            : "...",
                          sku: fetchedVariation.sku ? fetchedVariation.sku : "",
                          type: "variable",
                        };

                        const newVariationPostResponse = await postProduct(variationData);

                        if (!newVariationPostResponse.data.response) {
                          allRequestsSuccessful = false;
                        } else {
                          if (fetchedVariation.image) {
                            let data = {
                              imageUrls: [`${fetchedVariation.image.src}`],
                            };
                            const variationImageResponse = uploadProductImage({
                              id: newVariationPostResponse.data.response.id,
                              data,
                            });

                            // if (!variationImageResponse.data.response) {
                            //     return false;
                            // }
                          }
                        }
                      }
                    }
                  }
                }
              }
            } else {
              const filteredIds = savedCategories
                .filter((savedCategory) =>
                  product.categories.some(
                    (woocommerceCategory) => woocommerceCategory.id === savedCategory.woocommerce_id
                  )
                )
                .map((savedCategory) => savedCategory.id);

              // getting wholesale price as the reseller price
              let resellerPrice = 0;

              if (product.meta_data && product.meta_data.length > 0) {
                for (let meta_data of product.meta_data) {
                  if (meta_data.key && meta_data.key == "wholesale_customer_wholesale_price") {
                    resellerPrice = parseFloat(meta_data.value);
                  }
                  // else {
                  //   let tempData = {
                  //     id: product.id,
                  //     name: product.name,
                  //     parent_id: product.parent_id,
                  //     type: product.type,
                  //   }
                  //   noneWholeSaleProducts.push(tempData);
                  // }
                }
              }

              let variableParentPrice = resellerPrice;
              let variationProducts = fetchedProductVariations.filter(
                (item) => item.parent_id == product.id
              );

              if (variationProducts && variationProducts.length > 0) {
                let prices = [];
                for (let variationProduct of variationProducts) {
                  if (variationProduct.status == "publish") {
                    if (variationProduct.meta_data && variationProduct.meta_data.length > 0) {
                      for (let meta_data of variationProduct.meta_data) {
                        if (meta_data && meta_data.key &&
                          meta_data.key == "wholesale_customer_wholesale_price"
                        ) {
                          prices.push(parseFloat(meta_data.value));
                        }
                        // else {
                        //   let tempData = {
                        //     id: fetchedVariation.id,
                        //     name: fetchedVariation.name,
                        //     parent_id: fetchedVariation.parent_id,
                        //     type: fetchedVariation.type,
                        //   }
                        //   noneWholeSaleProducts.push(tempData);
                        // }
                      }
                    }
                  }
                }
                variableParentPrice = Math.min(...prices);
              }

              let isDigitalProduct = false;

              if (product.shipping_class == "physical") {
                isDigitalProduct = false;
              } else if (product.shipping_class == "digital") {
                isDigitalProduct = true;
              } else {
                if (product.virtual) {
                  isDigitalProduct = product.virtual;
                }
              }

              let data = {
                woocommerceId: product.id,
                parentId: 0,
                countryId: countryId,
                isDigitalGood: isDigitalProduct,
                inStock: product.stock_status == "instock" ? true : false,
                price: variableParentPrice
                  ? variableParentPrice
                  : resellerPrice
                  ? resellerPrice
                  : parseFloat(product.price),
                retailPrice: product.regular_price ? parseFloat(product.regular_price) : 0,
                isFeaturedProduct: false,
                urlId: urlId,
                digitalKeys:
                  isDigitalProduct && product.stock_status == "instock"
                    ? ["Pending", "Pending", "Pending", "Pending", "Pending"]
                    : [],
                images: [],
                attributes: [],
                categories: [...new Set(filteredIds)],
                averageRating: product.average_rating ? parseFloat(product.average_rating) : 5,
                description: product.description ? product.description : "...",
                name: replaceHTMLEntityAnd(product.name),
                ratingCount: product.rating_count,
                shortDescription: product.short_description ? product.short_description : "...",
                sku: product.sku,
                type: product.type,
              };

              const newVariableProductResponse = await postProduct(data);

              let newVariableProductId = newVariableProductResponse.data.response.id;

              if (newVariableProductResponse.data?.response) {
                if (product.images && product.images.length > 0) {
                  let imageArray = [];
                  for (let image of product.images) {
                    imageArray.push(`${image.src}`);
                  }
                  let data = {
                    imageUrls: imageArray,
                  };
                  const imageResponse = uploadProductImage({ id: newVariableProductId, data });

                  // if (!imageResponse.data.response) {
                  //     return false;
                  // }
                }
              }

              // Check if the response is successful based on your API's structure
              if (!newVariableProductResponse.data.response) {
                allRequestsSuccessful = false;
              } else {
                let filteredVariations = fetchedProductVariations.filter(
                  (item) =>
                    item.parent_id == newVariableProductResponse.data.response.woocommerce_id
                );

                if (filteredVariations && filteredVariations.length > 0) {
                  for (let variation of filteredVariations) {
                    if (variation.status == "publish") {
                      let isVariationDigitalProduct = false;

                      if (variation.shipping_class) {
                        if (variation.shipping_class == "physical") {
                          isVariationDigitalProduct = false;
                        } else if (variation.shipping_class == "digital") {
                          isVariationDigitalProduct = true;
                        } else {
                          if (variation.virtual) {
                            isVariationDigitalProduct = variation.virtual;
                          }
                        }
                      } else {
                        if (variation.virtual) {
                          isVariationDigitalProduct = variation.virtual;
                        } else {
                          isVariationDigitalProduct = isDigitalProduct;
                        }
                      }

                      let variationAttributes = [];

                      if (variation.attributes && variation.attributes.length > 0) {
                        for (let attribute of variation.attributes) {
                          let attributeData = {};
                          attributeData.attribute = attribute.name;
                          attributeData.option = attribute.option;
                          variationAttributes.push(attributeData);
                        }
                      }

                      // getting wholesale price as the reseller price
                      let resellerVariationPrice = 0;

                      if (variation.meta_data && variation.meta_data.length > 0) {
                        for (let meta_data of variation.meta_data) {
                          if (meta_data && meta_data.key && meta_data.key == "wholesale_customer_wholesale_price"
                          ) {
                            resellerPrice = parseFloat(meta_data.value);
                          }
                          // else {
                          //   let tempData = {
                          //     id: variation.id,
                          //     name: variation.name,
                          //     parent_id: variation.parent_id,
                          //     type: variation.type,
                          //   }
                          //   noneWholeSaleProducts.push(tempData);
                          // }
                        }
                      }

                      let variationData = {
                        woocommerceId: variation.id,
                        parentId: newVariableProductId,
                        countryId: countryId,
                        isDigitalGood: isVariationDigitalProduct,
                        inStock: variation.stock_status == "instock" ? true : false,
                        price: resellerVariationPrice
                          ? resellerVariationPrice
                          : variation.price
                          ? parseFloat(variation.price)
                          : 0,
                        retailPrice: variation.regular_price
                          ? parseFloat(variation.regular_price)
                          : 0,
                        isFeaturedProduct: false,
                        urlId: urlId,
                        digitalKeys:
                          isVariationDigitalProduct && variation.stock_status == "instock"
                            ? ["Pending", "Pending", "Pending", "Pending", "Pending"]
                            : [],
                        images: [],
                        attributes: variationAttributes,
                        categories: filteredIds,
                        averageRating: variation.average_rating
                          ? parseFloat(variation.average_rating)
                          : 5,
                        description: variation.description
                          ? variation.description
                          : product.description
                          ? product.description
                          : "...",
                        name: variation.name
                          ? replaceHTMLEntityAnd(variation.name)
                          : replaceHTMLEntityAnd(product.name),
                        ratingCount: variation.rating_count
                          ? variation.rating_count
                          : product.rating_count,
                        shortDescription: variation.short_description
                          ? variation.short_description
                          : product.short_description
                          ? product.short_description
                          : "...",
                        sku: variation.sku ? variation.sku : "",
                        type: "variable",
                      };

                      const variationResponse = await postProduct(variationData);

                      if (!variationResponse.data.response) {
                        allRequestsSuccessful = false;
                      } else {
                        if (variation.image) {
                          let data = {
                            imageUrls: [`${variation.image.src}`],
                          };
                          const variationImageResponse = uploadProductImage({
                            id: variationResponse.data.response.id,
                            data,
                          });

                          // if (!imageResponse.data.response) {
                          //     return false;
                          // }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      // console.log(noneWholeSaleProducts);
      // console.log(draftProducts);

      return allRequestsSuccessful;
    } catch (error) {
      console.error("Error sending POST requests:", error);
      return false;
    }
  };

  function createHierarchy(categories) {
    const categoryMap = {};

    // Build a map using category IDs
    categories.forEach((category) => {
      categoryMap[category.id] = { ...category, children: [] };
    });

    // Populate the children array
    categories.forEach((category) => {
      if (category.parent !== 0) {
        categoryMap[category.parent].children.push(categoryMap[category.id]);
      }
    });

    // Find top-level categories
    const topLevelCategories = categories.filter((category) => category.parent === 0);

    return topLevelCategories.map((category) => categoryMap[category.id]);
  }

  const deleteRecord = async (id) => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });
    try {
      deleteProduct(id)
        .then((response) => {
          if (response) {
            Notify.success("Succefully deleted!");
          } else {
            Notify.failure("Something went wrong!");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    } finally {
      Loading.remove(500);
    }
  };

  useEffect(() => {
    removeCachedData();
    return () => {
      dispatch(
        setSelectedCountryURL({
          id: 0,
          url: "",
          c_key: "",
          c_secret: "",
        })
      );

      dispatch(resetStockId());
    };
  }, []);

  const removeCachedData = () => {
    const cacheVariationPrefix = "cacheVariation";
    const shortDescriptionState = "shortDescriptionState";
    const descriptionState = "descriptionState";

    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith(cacheVariationPrefix)) {
        localStorage.removeItem(key);
      }
    });

    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith(shortDescriptionState)) {
        localStorage.removeItem(key);
      }
    });

    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith(descriptionState)) {
        localStorage.removeItem(key);
      }
    });
  };

  const massResetFeaturedProducts = async () => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });
    try {
      resetFeaturedProducts(selectedCountry.id).then((response) => {
        if (response.data?.status == "success") {
          Notify.success("Successfully resetted!");
        } else {
          Notify.failure("Something went wrong!");
        }
      });
    } catch (e) {
      console.log(e);
    } finally {
      Loading.remove(500);
    }
  };

  return (
    <Card>
      <CardHeader
        // avatar={<AccountCircleIcon fontSize="large" color="dark" />}
        title={<MDTypography color="info">Inventory</MDTypography>}
        action={
          <MDBox display="flex" alignItems="center">
            <MDButton
              variant="gradient"
              color="primary"
              sx={{ mr: 1 }}
              onClick={() => {
                Confirm.show(
                  `Please Confirm`,
                  "Are you sure you want to reset the featured products?",
                  "Yes",
                  "No",
                  () => {
                    massResetFeaturedProducts();
                  },
                  () => {},
                  {}
                );
              }}
            >
              Reset Featured Products
            </MDButton>
            {selectedURL && selectedURL.id ? (
              <ProductReorder
                openProductReorderDialog={openProductReorderDialog}
                setOpenProductReorderDialog={setOpenProductReorderDialog}
                reorderProducts={reorderProducts}
              />
            ) : (
              ""
            )}
            <MDButton
              variant="gradient"
              color="info"
              onClick={() => navigate.push(`/user/add-inventory`)}
            >
              Add New
            </MDButton>
          </MDBox>
        }
      />
      <CardContent id="wrapper">
        <br />
        {isUrlsByCountryIdSuccess && selectedURL.url == "" ? (
          <Alert severity="warning">Please select the country URL!</Alert>
        ) : (
          ""
        )}
        {
          isUrlsByCountryIdSuccess && (
            <GridContainer>
              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                {selectedURL && (
                  <CustomSelect
                    label="Select URL *"
                    inputProps={{
                      id: "url_id",
                      name: "url_id",
                    }}
                    value={selectedURL.id}
                    onChange={(e) => {
                      handleUrlChange(e.target.value);
                      dispatch(resetStockId());
                    }}
                    sx={{
                      "& .MuiInputBase-root .MuiSelect-select": {
                        height: "44px",
                        // color: "#fff",
                        // fontWeight: "400",
                      },
                      "& .MuiFormLabel-root": {
                        backgroundColor: "#fff",
                        // color: "#e0e1e5",
                        padding: "0 5px",
                      },
                    }}
                    // helperText={errors.country_id}
                    // error={errors.country_id}
                  >
                    <MenuItem disabled>URLs</MenuItem>
                    {urlsByCountryId.urls.map((prop, key) => (
                      <MenuItem key={key} value={prop.id}>
                        {prop.url}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                )}
              </GridItem>
            </GridContainer>
          )
          // : (
          //   <Alert severity="warning">No urls found under the selected country!</Alert>
          // )
        }
        <MDBox id="tableWrapper">
          {selectedURL.url ? (
            <DataTable
              id={tables.INVENTORY}
              headerActions={
                <MDBox>
                  <Tooltip title="Resync Products from the store" sx={{ ml: 1 }} placement="top">
                    <IconButton
                      aria-label="view"
                      size="small"
                      onClick={() =>
                        reSyncWithWoocommerce(
                          false,
                          selectedURL.id,
                          selectedURL.url,
                          selectedURL.c_key,
                          selectedURL.c_secret
                        )
                      }
                    >
                      <RestartAltIcon color="info" fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                  {filterProducts.length > 0 && <Tooltip title="Fetch New Products from the store" sx={{ ml: 1 }} placement="top">
                    <IconButton
                      aria-label="view"
                      size="small"
                      onClick={() =>
                        reSyncWithWoocommerce(
                          true,
                          selectedURL.id,
                          selectedURL.url,
                          selectedURL.c_key,
                          selectedURL.c_secret
                        )
                      }
                    >
                      <DownloadingIcon color="primary" fontSize="inherit" />
                    </IconButton>
                  </Tooltip>}
                </MDBox>
              }
              columns={[
                {
                  Header: "Image",
                  accessor: "image",
                  Cell: ({ row: { original } }) => (
                    <MDBox display="flex" alignItems="center" lineHeight={1}>
                      {imageLoading && (
                        <CircularProgress
                          color="primary"
                          sx={{
                            width: "10px",
                            height: "10px",
                          }}
                          size="small"
                        />
                      )}
                      <MDAvatar
                        src={
                          original.products_images &&
                          original.products_images.length > 0 &&
                          original.products_images[0] &&
                          original.products_images[0].src
                            ? imageSrcDecoder(original.products_images[0].src)
                            : noPreview
                        }
                        name={
                          original.products_images &&
                          original.products_images.length > 0 &&
                          original.products_images[0] &&
                          original.products_images[0].name
                        }
                        onLoad={() => setImageLoading(false)}
                        size="md"
                      />
                    </MDBox>
                  ),
                },
                {
                  Header: "Product Name",
                  accessor: "name",
                },
                {
                  Header: "Product Type",
                  accessor: "type",
                  Cell: ({ row: { original } }) => (
                    <span style={{ textTransform: "capitalize" }}>{original.type}</span>
                  ),
                },
                // {
                //   Header: "Price",
                //   accessor: "priceRange",
                //   Cell: ({ row: { original } }) => (
                //     <span>${selectedCountry.currency} {original.priceRange}</span>
                //   ),
                // },
                {
                  Header: "Stock Status",
                  accessor: "is_in_stock",
                  Cell: ({ row: { original } }) => {
                    if (original.type == "simple") {
                      return original.is_in_stock ? (
                        <>
                          <MDBox>
                            <MDBadge
                              badgeContent="In stock"
                              color="success"
                              variant="gradient"
                              size="sm"
                            />
                          </MDBox>
                        </>
                      ) : (
                        <MDBox>
                          <MDBadge
                            badgeContent="Out of stock"
                            color="error"
                            variant="gradient"
                            size="sm"
                          />
                        </MDBox>
                      );
                    } else {
                      return original.productVariations &&
                        original.productVariations.length > 0 &&
                        original.productVariations.filter(
                          (variation) => variation.is_in_stock == true
                        ).length > 0 ? (
                        <>
                          <MDBox>
                            <MDBadge
                              badgeContent="In stock"
                              color="success"
                              variant="gradient"
                              size="sm"
                            />
                          </MDBox>
                        </>
                      ) : (
                        <MDBox>
                          <MDBadge
                            badgeContent="Out of stock"
                            color="error"
                            variant="gradient"
                            size="sm"
                          />
                        </MDBox>
                      );
                    }
                  },
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                  disableSortBy: true,
                  disableFilters: true,
                  show: true,
                  align: "right",
                  // eslint-disable-next-line react/display-name
                  Cell: ({ row: { original } }) => (
                    <div className="actions-right">
                      {original.id == currentStockId.id && <div className="highlight-animation" style={{ position: 'absolute', borderRadius: 5, top: 0, right: 0, left: 0, bottom: 0 }}></div>}
                      {original.woocommerce_id != 0 && (
                        <Tooltip title="Resync product" sx={{ ml: 1 }} placement="top">
                          <IconButton
                            aria-label="view"
                            size="small"
                            onClick={() =>
                              singleProductReSyncWithWoocommerce(
                                selectedURL.id,
                                selectedURL.url,
                                selectedURL.c_key,
                                selectedURL.c_secret,
                                original.woocommerce_id
                              )
                            }
                          >
                            <RestartAltIcon color="info" fontSize="inherit" />
                          </IconButton>
                        </Tooltip>
                      )}
                      <AddToReOrderList
                        currentProductId={original.id}
                        currentPrority={original.priority}
                        currentProduct={original}
                        reorderProducts={reorderProducts}
                        setReorderProducts={setReorderProducts}
                      />
                      <EditInventoryDialog
                        elementId={`productId${original.id}`}
                        parentProductData={original}
                        distinctAttributes={distinctAttributes}
                      />
                      {original.type != "variable" && (
                        <Tooltip title="Delete" sx={{ ml: 1 }} placement="top">
                          <IconButton
                            aria-label="view"
                            size="small"
                            onClick={() => {
                              Confirm.show(
                                `Please Confirm`,
                                "Are you sure you want to delete?",
                                "Yes",
                                "No",
                                () => {
                                  deleteRecord(original.id);
                                },
                                () => {},
                                {}
                              );
                            }}
                          >
                            <Close color="error" fontSize="inherit" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                  ),
                },
              ]}
              data={filterProducts || []}
              isSorted={true}
              entriesPerPage={false}
              showTotalEntries={false}
              hideColumnSelection={false}
              noEndBorder
              canSearch
              // actionButton={
              //   <MDButton
              //     variant="gradient"
              //     color="primary"
              //     sx={{ ml: 2 }}
              //     onClick={handleClickOpen}
              //     disabled={urlsByCountryId.length == 0}
              //   >
              //     Add Stock
              //   </MDButton>
              // }
            />
          ) : (
            ""
          )}
        </MDBox>
      </CardContent>
    </Card>
  );
}

export default Inventory;
